import { Link } from 'react-router-dom'
import {Col} from 'reactstrap'
export const SysLogo = () => {
    //console.log(window.location.host)
    if (window.location.host === "sdk.mediafy.io" || window.location.host === "localhost:3000") {
        return require(`@src/assets/images/pages/mediafysdk.svg`).default
    } else if (window.location.host === "search.mars.media") {
        return require('@src/assets/images/logo/mars_logo.svg').default
    } else {
        return require('@src/assets/images/logo/optimus_studio.svg').default
    }
}
export const SysName = () => {
    console.log(window.location.host)
    if (window.location.host === "sdk.mediafy.io" || window.location.host === "localhost:3000") {
        return "Mediafy SDK"
    } else if (window.location.host === "search.mars.media") {
        return "Mars"
    } else {
        return "Optimus"
    }
}
export const SysMessIcone = () => {
    return require(`@src/assets/images/pages/mess_icon.svg`).default
}

export const SysLogoMini = () => {
    if (window.location.host === "search.mars.media") {
        return require('@src/assets/images/logo/mars_logo_min_black.svg').default
    } else {
        return require('@src/assets/images/logo/optimus_studio_min.svg').default
    }
}

export const SysLogoWhite = () => {
    if (window.location.host === "search.mars.media") {
        return require('@src/assets/images/logo/mars_logo_white.svg').default
    } else {
        return require('@src/assets/images/logo/optimus_studio_white.svg').default
    }
}

export const SysLogoWhiteMini = () => {
    if (window.location.host === "search.mars.media") {
        return require('@src/assets/images/logo/mars_logo_min_white.svg').default
    } else {
        return require('@src/assets/images/logo/optimus_studio_min_white.svg').default
    }
}

export const SrcLogo = () => {
    if (window.location.host === "sdk.mediafy.io" || window.location.host === "localhost:3000") {
        return require(`@src/assets/images/pages/threscreens.svg`).default
    } else {
        return require(`@src/assets/images/pages/castify_right_login_teaser2.jpg`).default
    }
}
export const mainHtml = () => {
    if (window.location.host === "sdk.mediafy.io" || window.location.host === "localhost:3000") {
        return (
            <Col 
            className='d-none d-lg-flex align-items-center justify-content-center p-0' 
            lg='8' 
            sm='12' 
            style={{
                background: 'radial-gradient(circle, rgba(0,13,126,1) 0%, rgba(0,7,48,1) 70%)',
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
                position: 'relative',
                height: '100vh'
            }}
            >
                <div>
                    <img src={SysLogo()} alt='Logo' style={{ width: '280px', marginBottom: '2rem' }} />
                </div>
                
                <div style={{ textAlign: 'center'}}>
                    <h1 style={{
                    color: '#ffffff',
                    fontSize: '4em',
                    fontWeight: 500,
                    fontFamily: '"Poppins", sans-serif',
                    margin: '2rem 4.5rem',
                    width:'700px'
                    }}>Connect, Analyse, Monetize</h1>
                    
                </div>
    
                <div>
                    <img src={SrcLogo()} alt='Table Elements' style={{ width: '600px', marginTop: '10%' }} />
                </div>
            </Col>
        )
    } else { 
        return (
            <>
                <Link className='brand-logo' to='/' onClick={e => e.preventDefault()}>
                    <img className='logo' src={SysLogo()} alt='Logo' />
                </Link>
                <Col className='d-none d-lg-flex align-items-end p-0' lg='8' sm='12' style={{
                    backgroundColor: '#fff',
                    backgroundImage: `url(${SrcLogo()})`,
                    backgroundPosition: 'bottom right',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat'
                }}>
                </Col>
            </>
        )
    }
}

export const mainHtmlCreateUser = () => {
    if (window.location.host === "sdk.mediafy.io" || window.location.host === "localhost:3000") {
        return (
            <Col 
            className='d-none d-lg-flex align-items-center justify-content-center p-0' 
            lg='8' 
            sm='12' 
            style={{
                background: 'radial-gradient(circle, rgba(0,13,126,1) 0%, rgba(0,7,48,1) 70%)',
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
                position: 'relative',
                height: '100vh'
            }}
            >
                <div>
                    <img src={SysLogo()} alt='Logo' style={{ width: '280px', marginBottom: '2rem' }} />
                </div>
                
                <div style={{ textAlign: 'center'}}>
                    <h1 style={{
                    color: '#ffffff',
                    fontSize: '4em',
                    fontWeight: 500,
                    fontFamily: '"Poppins", sans-serif',
                    margin: '2rem 4.5rem',
                    width:'700px'
                    }}>Connect, Analyse, Monetize</h1>
                    
                </div>
    
                <div>
                    <img src={SrcLogo()} alt='Table Elements' style={{ width: '600px', marginTop: '10%' }} />
                </div>
            </Col>
        )
    } 
}